import { Select } from '@backstage/core-components';
import React, { useEffect, useState } from 'react';
import { Guides } from './Guides';
import { PanelHeader } from './PanelHeader';

type Guide = {
  title: string;
  description: string;
  link: string;
  subheader: string;
  labels: string[];
};
const GUIDES: Guide[] = [
  {
    title: 'Ephemeral Production Data Seeding',
    description:
      'Learn how to seed ephemeral subscriptions with production data sources',
    link: 'https://backstage.dev.qmengineering.com/catalog/default/component/backstage/docs/plugins/subscriptions/ephemeral-data-seeding/',
    subheader: 'Ephemeral Production Data Seeding',
    labels: ['dxp'],
  },
  {
    title: 'QM Architecture',
    description: 'High level overview of Quantum Metric Architecture',
    link: 'https://backstage.dev.qmengineering.com/docs/default/component/qm-architecture',
    subheader: 'Quantum Metric Architecture',
    labels: ['architecture'],
  },
  {
    title: 'Gitlab CI Components',
    description:
      'Learn how to utilize Gitlab CI components to build your CI/CD pipelines',
    link: 'https://backstage.dev.qmengineering.com/catalog/default/component/pipeline-components-guide/docs/',
    subheader: 'Gitlab CI Components',
    labels: ['dxp', 'cicd'],
  },
  {
    title: 'Product Permissions',
    description:
      'Learn how our Product Permission system is used to control features within the Quantum Metric UI',
    link: 'https://backstage.dev.qmengineering.com/docs/default/component/product-permissions-docs',
    subheader: 'DXP',
    labels: ['dxp'],
  },
  {
    title: 'Analytics JSON',
    description: 'Learn how to read the Analytics Payload JSON',
    link: 'https://backstage.dev.qmengineering.com/docs/default/system/monorepo/guides/analytics-payload-json/#how-to-read-the-analytics-payload-json',
    subheader: 'Analytics',
    labels: ['analytics'],
  },
  {
    title: 'Tagging Subscriptions',
    description:
      'Learn more about deploying a change to a single subscription.',
    link: 'https://backstage.dev.qmengineering.com/docs/default/component/tagging-subscriptions-guide/',
    subheader: 'DXP',
    labels: ['dxp'],
  },
  {
    title: 'Incident Management',
    description:
      'Learn about the Incident Management Process at Quantum Metric',
    link: 'https://backstage.dev.qmengineering.com/docs/default/Component/incident-management-guide/',
    subheader: 'Operations',
    labels: ['operations'],
  },
  {
    title: 'Automated Testing',
    description: 'Learn more about how Quantum Metric does automated testing.',
    link: 'https://backstage.dev.qmengineering.com/docs/default/component/automated-testing-guide',
    subheader: 'DXP',
    labels: ['dxp', 'testing'],
  },
  {
    title: 'Feature Flags',
    description:
      'Learn more about using feature flags to support feature development and decouple deployments from releases.',
    link: 'https://backstage.dev.qmengineering.com/docs/default/component/feature-flags-docs/guides/implementing-feature-flag/',
    subheader: 'DXP',
    labels: ['dxp'],
  },
  {
    title: 'Sending Emails',
    description:
      'Learn more about sending emails programatically at Quantum Metric.',
    link: 'https://backstage.dev.qmengineering.com/docs/default/Component/qm-email-guide',
    subheader: 'Notifications',
    labels: ['email', 'notifications'],
  },
  {
    title: 'System Model',
    description:
      "Learn more about adding components to Backstage's system model catalog, and creating discoverable documentation.",
    link: 'https://backstage.dev.qmengineering.com/docs/default/component/backstage/usage/system-model/',
    subheader: 'DXP',
    labels: ['dxp'],
  },
  {
    title: 'TechDocs',
    description:
      'TechDocs is docs-as-code solution offered in Backstage with minimal configuration resulting in a discoverable and slick doc site.',
    link: 'https://backstage.dev.qmengineering.com/docs/default/component/backstage/usage/techdocs/',
    subheader: 'Documentation',
    labels: ['dxp'],
  },
  {
    title: 'Logging',
    description:
      'Learn about centralized logging at Quantum Metric using Grafana Loki',
    link: 'https://backstage.dev.qmengineering.com/docs/default/component/logging',
    subheader: 'Observability',
    labels: ['alerting', 'observability'],
  },
  {
    title: 'Guides',
    description:
      'Use guides to provide detailed information and best practices on a given topic or domain',
    link: 'https://backstage.dev.qmengineering.com/docs/default/component/backstage/contributing/guide',
    subheader: 'Documentation',
    labels: ['dxp'],
  },
  {
    title: 'Python gRPC Template',
    description:
      'Learn how to use the Python gRPC Template for setting up new Python gRPC services',
    link: 'https://backstage.dev.qmengineering.com/docs/default/Component/python-grpc-service/usage',
    subheader: 'Templating',
    labels: ['python'],
  },
  {
    title: 'Rollback UI',
    description:
      'Use this guide to promote a previous UI bundle to production for the web-ui',
    link: 'https://backstage.dev.qmengineering.com/docs/default/system/monorepo/guides/rollback-ui',
    subheader: 'Documentation',
    labels: ['dxp'],
  },
  {
    title: 'Element Vein (Clicked Zones)',
    description:
      "Understand QM's new Element Vein compared to Clicked Text/Clicked Element",
    link: 'https://backstage.dev.qmengineering.com/docs/default/system/monorepo/guides/element-vein/',
    subheader: 'Analytics',
    labels: ['analytics'],
  },
];

const getUniqueLabels = (g: Guide[]) => {
  const allLabels = g.reduce((acc: any, guide: Guide) => {
    acc.push(...guide.labels);
    return acc;
  }, []);

  return [...new Set(allLabels)];
};

export const GuidePanel = () => {
  const [guides, setGuides] = useState<Guide[]>(GUIDES);
  const [tags, setTags] = useState<string[]>([]);

  const labels: any = getUniqueLabels(GUIDES).sort((a: any, b: any) =>
    a.toLowerCase().localeCompare(b.toLowerCase()),
  );
  const items: any = [];
  labels.forEach((l: string[]) => {
    items.push({ label: l, value: l });
  });

  useEffect(() => {
    if (tags.length === 0) {
      setGuides(GUIDES);
      return;
    }
    // Filter guides based on the presence of any tag in the labels array
    const filtered = GUIDES.reduce((acc: any, guide: any) => {
      // Check if the current guide has any tag that matches the filter tags
      const hasMatchingTag = guide.labels.some((label: any) =>
        tags.includes(label),
      );

      // If a matching tag is found, add the guide to the accumulator
      if (hasMatchingTag) {
        acc.push(guide);
      }
      return acc;
    }, []);

    setGuides(filtered);
  }, [tags]);

  const handleChange = (val: any) => {
    setTags(val);
  };

  return (
    <div>
      <PanelHeader title="Guides" />

      <div style={{ padding: '10px' }}>
        <div style={{ paddingLeft: '10px' }}>
          <Select
            placeholder="Tags"
            label=""
            items={items}
            multiple
            onChange={handleChange}
          />
        </div>
        <Guides guides={guides} />
      </div>
    </div>
  );
};
