import React, { useEffect, /* useMemo */ useState } from 'react';
import {
  Content,
  ContentHeader,
  SupportButton,
} from '@backstage/core-components';
import { updatedEntity } from '../types';
import { fetchResults } from '../api';
import { getScore, getServiceName, shapeResults, SUPPORT_TEXT } from '../util';
// import SummaryCard from './SummaryCard';
import DetailCard from './DetailCard';
import { Button } from '@material-ui/core';
import { useApi, configApiRef } from '@backstage/core-plugin-api';

export const ExcellenceComponent = () => {
  const config = useApi(configApiRef);
  const [isLoading, setIsLoading] = useState(false);
  const [entity, setEntity] = useState<updatedEntity | null>(null);
  const [apiFailed, setApiFailed] = useState<boolean>(false);

  const runAPI = () => {
    setIsLoading(true);
    setApiFailed(false);
    fetchResults(config, getServiceName() ?? 'resource-loader').then(res => {
      if (res && !apiFailed) {
        const { results } = res;
        const shapedResults = shapeResults(results);
        setEntity({ ...res, results: shapedResults });
      } else {
        setApiFailed(true);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    runAPI();
    // Shutup react, it's compDidMount, chill
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*   const summaryItems = useMemo(() => {
    if (entity !== null) {
      return Object.values(entity.results).reduce<string[]>((acc, result) => {
        if (result.failResults.length) {
          const improvementItems = result.failResults.reduce<string[]>(
            (accDeux, res) => {
              if (res.remedy) accDeux.push(res.remedy.summary);
              return accDeux;
            },
            [],
          );
          // eslint-disable-next-line no-param-reassign
          acc = acc.concat(improvementItems);
        }
        return acc;
      }, []);
    }
    return [];
  }, [entity]); */
  if (apiFailed)
    return (
      <Button variant="contained" color="secondary" onClick={runAPI}>
        Retry
      </Button>
    );
  return (
    <Content>
      <ContentHeader title="Operational Excellence">
        <SupportButton>{SUPPORT_TEXT}</SupportButton>
      </ContentHeader>
      {/*       <SummaryCard
        health={entity?.ox_score ?? 'unknown'}
        isLoading={isLoading}
        listItems={summaryItems}
      /> */}

      {entity
        ? Object.entries(entity.results).map(([key, val]) => {
            const { passResults, failResults } = val;
            return (
              <DetailCard
                key={key}
                namespace={key}
                health={getScore(key, entity.namespaces)}
                isLoading={isLoading}
                passResults={passResults}
                failResults={failResults}
              />
            );
          })
        : null}
    </Content>
  );
};
