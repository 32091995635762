import { Entity } from './types';

const getAPIUrl = (config: { getString: (key: string) => string }): string => {
  return config.getString('ox.apiUrl');
};

const fetchResults = (
  config: { getString: (key: string) => string },
  service: string,
): Promise<undefined | Entity> => {
  const baseURL = getAPIUrl(config);

  return fetch(`${baseURL}api/v1/scores/${service}`)
    .then<Entity>(res => {
      if (res.ok) {
        return res.json();
      }
      throw Error('API failed');
    })
    .catch(() => {
      // console.log(e);
      return undefined;
    });
};

const fetchEntities = (config: {
  getString: (key: string) => string;
}): Promise<Entity[]> => {
  const baseURL = getAPIUrl(config);
  const data = fetch(`${baseURL}api/v1/scores`).then<Entity[]>(response =>
    response.json(),
  );
  return data;
};

export { fetchResults, fetchEntities };
