import React, { useContext, useState, useEffect, ChangeEvent } from 'react';
import { PipelineTypeContext } from './PipelineTypeProvider';
import { Grid, MenuItem, Select } from '@material-ui/core';
import { SELECTOR_ITEMS, PipelineType } from '../../utils/pipelineTypes';

function PipelineTypeSelectorComponent() {
  const [pipelineType, setPipelineType] = useContext(PipelineTypeContext);
  const [selectedType, setSelectedType] = useState<PipelineType>(pipelineType);

  useEffect(() => {
    const updateURL = () => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('pipelineType', selectedType);
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.pushState({}, '', newUrl);
    };

    updateURL();
  }, [selectedType]);

  const handleSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as PipelineType;
    setSelectedType(newValue);
    setPipelineType(newValue);
  };

  window.addEventListener('beforeunload', () => {
    localStorage.removeItem('pipelineType');
  });

  return (
    <Grid>
      <Select
        onChange={handleSelectChange}
        multiple={false}
        value={selectedType}
        variant="outlined"
      >
        {SELECTOR_ITEMS.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
}

export default PipelineTypeSelectorComponent;
