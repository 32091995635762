/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useEffect } from 'react';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';
import mermaid from 'mermaid';
import { MermaidProps } from './props';
import { defaultConfig } from './defaults';
import { isMermaidCode } from './hooks';

/**
 * Show report issue button when text is highlighted
 */

let diagramId = 0;

export const MermaidAddon = (properties: MermaidProps) => {
  const preBlocks = useShadowRootElements<HTMLDivElement>(['pre']);

  useEffect(() => {
    preBlocks.forEach(preBlock => {
      // Mermaid is currently classified as "text"
      // https://github.com/backstage/backstage/pull/11447 which adds this class isn't
      // shipped yet.
      // if (!highlightTable.classList.contains('language-text')) {
      //   return;
      // }

      // Skip already processed
      if (preBlock.style.display === 'none') {
        return;
      }

      const codeBlock = preBlock.querySelector('code');

      if (!codeBlock) {
        return;
      }

      const diagramText = codeBlock.innerText;

      if (
        !codeBlock.classList.contains('language-mermaid') &&
        !isMermaidCode(diagramText)
      ) {
        return;
      }

      preBlock.style.display = 'none';

      const diagramElement = document.createElement('div');
      diagramElement.className = 'mermaid';

      preBlock.parentNode?.insertBefore(diagramElement, preBlock.nextSibling);

      const id = `mermaid-${diagramId++}`;
      mermaid.initialize({
        ...defaultConfig,
        ...properties?.config,
      });
      mermaid.render(id, diagramText, (svgGraph: string) => {
        diagramElement.innerHTML = svgGraph;
      });
    });
  }, [preBlocks, properties]);

  return null;
};
