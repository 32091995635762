import { Result } from './types';

export const getReliabilityStatus = (allInfo: Result[]) => {
  const successValues = allInfo?.map(({ status, passRate }) => {
    let value;
    if (status === 'pass' || passRate) {
      value = status ? 1 : passRate;
    } else if (status === 'retry' || passRate) {
      value = status ? 0.5 : passRate;
    } else {
      value = 0;
    }
    return value;
  });

  const reliabilityData = (() => {
    const recentStatusArray = successValues?.reverse().slice(-20);
    const lastTwoEntries = recentStatusArray?.slice(-2);

    const countFailures = recentStatusArray?.filter(
      (_value: number, index: number, array: number[]) => {
        return (
          index < array.length - 1 &&
          array[index] === 0 &&
          array[index + 1] === 1
        );
      },
    ).length;

    const countRetries = recentStatusArray?.filter(
      (value: number) => value === 0.5,
    ).length;

    let status;
    let color;

    if (!recentStatusArray || recentStatusArray.length < 5) {
      status = 'Not Enough Data';
      color = 'gray';
    } else if (lastTwoEntries.every((value: number) => value === 0)) {
      status = 'Broken';
      color = 'red';
    } else if (countRetries >= 4) {
      status = 'Frequent Retries';
      color = 'orange';
    } else if (countFailures >= 2) {
      status = 'Unreliable';
      color = 'red';
    } else {
      status = 'Reliable';
      color = 'green';
    }
    return { status, color };
  })();

  const averageSuccess =
    successValues?.reduce((sum, value) => sum + value, 0) /
    successValues?.length;

  const trendLineColor = averageSuccess > 0.9001 ? 'green' : 'red';

  const durationValues = allInfo?.map(({ duration, avgDuration }) => {
    const valueToMap = duration !== undefined ? duration : avgDuration;
    return valueToMap;
  });

  return {
    score: reliabilityData.status,
    color: reliabilityData.color,
    durationValues,
    trendLineColor,
    successValues,
  };
};

export const formatName = (input: string) => {
  const readableTestName = input
    .replace(/hashtag/g, '#')
    .replace(/[-]+/g, ' ')
    .replace(/[ ]+/g, ' ')
    .replace(/["'/:,\s]+/g, ' ')
    .trim()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return readableTestName;
};
