import { Button, HorizontalScrollGrid } from '@backstage/core-components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

type GuideProps = {
  title: string;
  description: string;
  link: string;
  subheader: string;
  labels: string[];
};

const useStyles = makeStyles({
  header: {
    height: '100px',
    maxHeight: '100px',
  },
  chips: {
    margin: 20,
  },
  guideCard: {
    margin: 20,
    width: 400,
    minWidth: 300,
    minHeight: 300,
  },
  guideButton: {
    color: '#E6005C',
  },
});

const Guide = (props: GuideProps) => {
  const classes = useStyles();
  const { title, description, link, labels = [], subheader } = props;
  return (
    <Card className={classes.guideCard}>
      <CardMedia>
        <CardHeader
          title={title}
          subheader={subheader}
          classes={{ root: classes.header }}
        />
      </CardMedia>
      <CardContent>{description}</CardContent>
      <div className={classes.chips}>
        {labels.map(label => {
          return <Chip label={label} size="small" variant="default" />;
        })}
      </div>
      <CardActions>
        <Button className={classes.guideButton} color="primary" to={link}>
          Read
        </Button>
      </CardActions>
    </Card>
  );
};

export const Guides = (props: any) => {
  const { guides } = props;
  return (
    <HorizontalScrollGrid scrollStep={300}>
      {guides.map((guide: any) => {
        const { title, description, link, subheader, labels } = guide;
        return (
          <Guide
            title={title}
            description={description}
            link={link}
            subheader={subheader}
            labels={labels}
          />
        );
      })}
    </HorizontalScrollGrid>
  );
};
