import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const qmOxPlugin = createPlugin({
  id: 'qm-ox',
  routes: {
    root: rootRouteRef,
  },
});

export const QmOxPage = qmOxPlugin.provide(
  createRoutableExtension({
    name: 'QmOxPage',
    component: () =>
      import('./components/ExcellencePage').then(m => m.ExcellencePage),
    mountPoint: rootRouteRef,
  }),
);

export const QmOxSummaryPage = qmOxPlugin.provide(
  createRoutableExtension({
    name: 'QmOxSummaryPage',
    component: () =>
      import('./components/ExcellenceSummaryPage').then(
        m => m.ExcellenceSummaryPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
