import React, { useState, createContext, useEffect } from 'react';

import { useIamProxy } from '@internal/iam-common';
import { ConfigEnvironmnet } from './constants';
import { toShortEnv } from '../../utiils';

export const ConfigurationContext = createContext<any>(null);

type ProviderProps = {
  children: any;
};

const ConfigurationProvider = ({ children }: ProviderProps) => {
  const [env, setEnv] = useState<string>(ConfigEnvironmnet.Development);
  const [services, setServices] = useState<any>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchServices = useIamProxy();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const url = `https://config.${toShortEnv(
          env,
        )}.qmengineering.com/v1/services`;
        const { data, error: proxyError } = await fetchServices(url, {
          method: 'GET',
          env: env.toLowerCase(),
          headers: {
            'Content-type': 'application/json',
          },
        });
        const results = data ? data.results : [];
        setServices(results);
        setError(proxyError);
      } catch (err: any) {
        setError(err.message);
      }
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env]);

  return (
    <ConfigurationContext.Provider
      value={{
        setEnv,
        env: env.toLowerCase(),
        services,
        error,
        loading,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};
export default ConfigurationProvider;
