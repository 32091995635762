import React, { useContext } from 'react';
import { Link, Grid, Typography } from '@material-ui/core';
import { useApi, featureFlagsApiRef } from '@backstage/core-plugin-api';
import { InfoCard } from '@backstage/core-components';
import Edit from '@material-ui/icons/Edit';
import { InstanceContext } from '../Context';
import { FeatureFlags } from '../../flags';

const formatDate = (date: Date) => {
  return date.toLocaleString('en-EN', {
    dateStyle: 'short',
    timeStyle: 'short',
  });
};

export const InstanceOverviewCard = () => {
  const { instance } = useContext(InstanceContext);
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const serviceVersionClickEnabled = featureFlagsApi.isActive(
    FeatureFlags.ServiceVersionClickEnabled,
  );

  if (!instance) return null;
  const { key, author, version, created_at, updated_at, service_version } =
    instance;

  return (
    <InfoCard
      variant="gridItem"
      title="Overview"
      action={<Edit color="action" style={{ margin: 4 }} />}
    >
      <Grid container spacing={2}>
        <Grid container item>
          <Grid item xs={4}>
            <Typography variant="caption" color="textSecondary">
              KEY
            </Typography>
            <Typography>{key}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption" color="textSecondary">
              AUTHOR
            </Typography>
            <Typography>{author}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption" color="textSecondary">
              VERSION
            </Typography>
            <Typography>
              {serviceVersionClickEnabled ? (
                <Link href="_blank">{version}</Link>
              ) : (
                version
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={4}>
            <Typography variant="caption" color="textSecondary">
              CREATED
            </Typography>
            <Typography>{formatDate(new Date(created_at))}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption" color="textSecondary">
              UPDATED
            </Typography>
            <Typography>{formatDate(new Date(updated_at))}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption" color="textSecondary">
              SERVICE_VERSION
            </Typography>
            <Typography>
              {serviceVersionClickEnabled ? (
                <Link href="_blank">{service_version}</Link>
              ) : (
                service_version
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
