import {
  createPlugin,
  createComponentExtension,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const qmEphemeralSubscriptionsPlugin = createPlugin({
  id: 'qm-ephemeral-subscriptions',
  routes: {
    root: rootRouteRef,
  },
});

export const QmEphemeralSubscriptionsPage =
  qmEphemeralSubscriptionsPlugin.provide(
    createRoutableExtension({
      name: 'QmEphemeralSubscriptionsPage',
      component: () =>
        import('./components/SubscriptionListComponent').then(
          m => m.SubscriptionListComponent,
        ),
      mountPoint: rootRouteRef,
    }),
  );

export const QmEphemeralSubscriptionDrilldownPage =
  qmEphemeralSubscriptionsPlugin.provide(
    createRoutableExtension({
      name: 'QmEphemeralSubscriptionDrilldownPage',
      component: () =>
        import('./components/SubscriptionPage').then(m => m.SubscriptionPage),
      mountPoint: rootRouteRef,
    }),
  );

export const SubscriptionsCard = qmEphemeralSubscriptionsPlugin.provide(
  createComponentExtension({
    name: 'SubscriptionsCard',
    component: {
      lazy: () =>
        import('./components/SubscriptionsCard').then(m => m.SubscriptionsCard),
    },
  }),
);
