import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Typography, Box } from '@material-ui/core';
import { useDateRange } from './DateRangeContext';

const DateRanges = {
  TODAY: 'today',
  LAST_SEVEN_DAYS: 'last_7_days',
  LAST_14_DAYS: 'last_14_days',
  LAST_30_DAYS: 'last_30_days',
};

const predefinedRanges = [
  { label: 'Today', value: DateRanges.TODAY },
  { label: 'Last 7 Days', value: DateRanges.LAST_SEVEN_DAYS },
  { label: 'Last 14 Days', value: DateRanges.LAST_14_DAYS },
  { label: 'Last 30 Days', value: DateRanges.LAST_30_DAYS },
];

function DateRangeSelectorComponent() {
  const getUrlParams = new URLSearchParams(window.location.search).get(
    'dateRange',
  );
  const { dateRange, setRange } = useDateRange();
  const [selectedValue, setSelectedValue] = useState<string>(() => {
    if (dateRange.timeRange) {
      return dateRange.timeRange;
    } else if (getUrlParams) {
      return getUrlParams;
    }
    return DateRanges.TODAY;
  });

  useEffect(() => {
    const updateDateRange = () => {
      const today = new Date();
      let startDate: Date = today;
      let endDate: Date = today;

      if (selectedValue === DateRanges.LAST_SEVEN_DAYS) {
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 6);
        endDate = today;
      } else if (selectedValue === DateRanges.LAST_14_DAYS) {
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 13);
        endDate = today;
      } else if (selectedValue === DateRanges.TODAY) {
        startDate = today;
        endDate = today;
      } else if (selectedValue === DateRanges.LAST_30_DAYS) {
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 29);
        endDate = today;
      }

      const newDateRange = {
        startDate: startDate ? startDate.toISOString().split('T')[0] : '',
        endDate: endDate ? endDate.toISOString().split('T')[0] : '',
        timeRange: selectedValue,
      };

      // Check if the new date range is different from the current one before updating
      if (
        newDateRange.startDate !== dateRange.startDate ||
        newDateRange.endDate !== dateRange.endDate ||
        newDateRange.timeRange !== dateRange.timeRange
      ) {
        setRange(newDateRange);
        // Update the URL with the selected date range
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('dateRange', newDateRange.timeRange);
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
      }
    };

    updateDateRange();
  }, [selectedValue, dateRange, setRange]);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    setSelectedValue(newValue);
  };

  const longestLabelWidth = Math.max(
    ...predefinedRanges.map(range => range.label.length),
  );

  const selectStyle = {
    minWidth: `${longestLabelWidth + 4}ch`,
    maxWidth: `${longestLabelWidth + 4}ch`,
  };

  const formattedStartDate = new Date(dateRange.startDate)
    .toISOString()
    .split('T')[0];

  const formattedEndDate = new Date(dateRange.endDate)
    .toISOString()
    .split('T')[0];

  return (
    <Box display="flex">
      <Select
        value={selectedValue}
        onChange={handleSelectChange}
        variant="outlined"
        style={{ ...selectStyle, marginRight: 8 }}
        fullWidth
      >
        {predefinedRanges.map(range => (
          <MenuItem key={range.value} value={range.value}>
            {range.label}
          </MenuItem>
        ))}
      </Select>
      <Box display="flex" flexDirection="column" style={{ marginRight: 8 }}>
        <Typography variant="caption">Date Range</Typography>
        <Typography>
          {formattedStartDate}
          {' - '}
          {formattedEndDate}
        </Typography>
      </Box>
    </Box>
  );
}

export default DateRangeSelectorComponent;
