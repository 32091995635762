import React, { useContext } from 'react';
import { CodeSnippet, InfoCard } from '@backstage/core-components';
import Edit from '@material-ui/icons/Edit';
import { InstanceContext } from '../Context';

export const InstanceBodyCard = () => {
  const { instance } = useContext(InstanceContext);

  const body = instance?.body || 'Body not defined';

  return (
    <InfoCard
      variant="gridItem"
      title="Body"
      action={<Edit style={{ margin: 4 }} color="action" />}
    >
      <CodeSnippet
        text={JSON.stringify(body, null, 2)}
        language="json"
        showCopyCodeButton
      />
    </InfoCard>
  );
};
