import { ConfigEnvironmnet } from './components/Context';

export const toLongEnv = (env: string): string => {
  if (env.toLowerCase() === ConfigEnvironmnet.ProductionShort.toLowerCase()) {
    return ConfigEnvironmnet.Production;
  }
  return ConfigEnvironmnet.Development;
};

export const toShortEnv = (env: string | null): string => {
  if (env && env.toLowerCase() === ConfigEnvironmnet.Production.toLowerCase()) {
    return ConfigEnvironmnet.ProductionShort;
  }
  return ConfigEnvironmnet.DevelopmenShort;
};
