import { Grid, Typography } from '@material-ui/core';
import {
  InfoCard,
  TrendLine,
  StructuredMetadataTable,
} from '@backstage/core-components';
import { getReliabilityStatus } from '../../utils/fns';
import React, { useEffect, useRef, useState } from 'react';
import { Result } from '../../utils/types';

const cardContentStyle = { height: 128.35 };

type Props = {
  successRate: number;
  avgDuration: number;
  executions: number;
  allInfo: Result[];
};

const DetailSummaryPanel = (props: Props) => {
  const { successRate, avgDuration, executions, allInfo } = props;
  const [success, setSuccess] = useState<number[]>([]);
  const [duration, setDuration] = useState<number[]>([]);

  const updatedMetadataRef = useRef<{
    broken_tests: string;
    unreliable_tests: string;
    tests_with_retries: string;
  }>({
    broken_tests: '',
    unreliable_tests: '',
    tests_with_retries: '',
  });

  useEffect(() => {
    const statusData = getReliabilityStatus(allInfo);

    setSuccess(statusData.successValues);

    const secondsArray: number[] = statusData.durationValues?.map(
      milliseconds => {
        return milliseconds / 1000 / 60;
      },
    );

    setDuration(secondsArray?.reverse());

    let countNoData = 0;
    let countBroken = 0;
    let countUnreliable = 0;
    let countFrequentRetries = 0;
    let countReliable = 0;

    allInfo?.forEach(({ recentPassArray }) => {
      const arrayValue = recentPassArray?.split(',');
      const recentStatusArray = arrayValue?.reverse().map(Number);
      const lastTwoEntries = recentStatusArray?.slice(-2);

      const countFailures = recentStatusArray?.filter(
        (_value: number, index: number, array: number[]) => {
          return (
            index < array?.length - 1 &&
            array[index] === 0 &&
            array[index + 1] === 1
          );
        },
      )?.length;

      const countRetries = recentStatusArray?.filter(
        (value: number) => value === 0.5,
      )?.length;

      if (!recentStatusArray || recentStatusArray?.length < 5) {
        countNoData++;
      } else if (lastTwoEntries.every((value: number) => value === 0)) {
        countBroken++;
      } else if (countRetries >= 4) {
        countFrequentRetries++;
      } else if (countFailures >= 2) {
        countUnreliable++;
      } else {
        countReliable++;
      }
    });

    const totalTests = allInfo?.length;
    const noDataPercentage = (countNoData / totalTests) * 100;
    const frequentRetriesPercentage = (countFrequentRetries / totalTests) * 100;
    const brokenPercentage = (countBroken / totalTests) * 100;
    const unreliablePercentage = (countUnreliable / totalTests) * 100;
    const reliablePercentage = (countReliable / totalTests) * 100;

    // Update the metadata object with real data counts
    const updatedMetadata = {
      broken_tests: `${countBroken} (${brokenPercentage.toFixed(2)}%)`,
      unreliable_tests: `${countUnreliable} (${unreliablePercentage.toFixed(
        2,
      )}%)`,
      tests_with_retries: `${countFrequentRetries} (${frequentRetriesPercentage.toFixed(
        2,
      )}%)`,
      reliable_tests: `${countReliable} (${reliablePercentage.toFixed(2)}%)`,
      no_data_tests: `${countNoData} (${noDataPercentage.toFixed(2)}%)`,
    };

    updatedMetadataRef.current = updatedMetadata;
  }, [allInfo]);

  const minutes = Math.floor(avgDuration / 1000 / 60);
  const seconds = avgDuration / 1000 - minutes * 60;

  return (
    <>
      <Grid container item direction="row">
        <Grid item xs={3}>
          <InfoCard title="Success Rate">
            <div style={cardContentStyle}>
              <Typography variant="h3">{successRate.toFixed(2)}%</Typography>
              <TrendLine
                data={success}
                color={successRate > 90 ? 'green' : 'red'}
                title="Trend over time"
              />
            </div>
          </InfoCard>
        </Grid>
        <Grid item xs={3}>
          <InfoCard title="Reliability Report">
            <div style={cardContentStyle}>
              <StructuredMetadataTable metadata={updatedMetadataRef.current} />
            </div>
          </InfoCard>
        </Grid>
        <Grid item xs={3}>
          <InfoCard title="Average Duration">
            <div style={cardContentStyle}>
              <Typography variant="h3">
                {minutes}m{seconds.toFixed(0)}s
              </Typography>
              <TrendLine
                data={duration}
                color="green"
                title="Trend over time"
              />
            </div>
          </InfoCard>
        </Grid>
        <Grid item xs={3}>
          <InfoCard title="Executions">
            <div style={cardContentStyle}>
              <Typography variant="h3">{executions}</Typography>
              <TrendLine
                data={[0.25, 0.5, 0.75, 1]}
                color="#E6005C"
                title="Trend over time"
              />
            </div>
          </InfoCard>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailSummaryPanel;
