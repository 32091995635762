import React, { useEffect, useMemo } from 'react';
import { Chip, Grid } from '@material-ui/core';
import {
  Link,
  Table,
  TableColumn,
  StatusOK,
  StatusWarning,
  StatusError,
} from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import type { Result } from '../../utils/types';
import { formatName } from '../../utils/fns';

type Props = {
  allInfo: Result[];
};

const renderLink = (rowData: any): JSX.Element => {
  const { name, namespace } = rowData;
  const encodedName = encodeURI(name);
  const formattedName = formatName(name);
  const searchParams = new URLSearchParams(window.location.search);
  const selectedRange = searchParams.get('dateRange');
  const ctxPipelineType = searchParams.get('pipelineType');

  return (
    <Link
      to={`/qm-test-metrics/${namespace}/test/${encodedName}?dateRange=${selectedRange}&pipelineType=${ctxPipelineType}`}
    >
      {formattedName}
    </Link>
  );
};

const renderDuration = (rowData: any): string => {
  const { avgDuration } = rowData;
  const minutes = Math.floor(avgDuration / 60000);
  const remainingSeconds = Math.floor((avgDuration % 60000) / 1000);
  return `${minutes}m${remainingSeconds}s`;
};

const renderReliabilityScore = (rowData: any) => {
  const { recentPassArray } = rowData;
  const arrayValue = recentPassArray.split(',');
  const recentStatusArray = arrayValue?.reverse().map(Number);
  const lastTwoEntries = recentStatusArray.slice(-2);

  const countFailures = recentStatusArray?.filter(
    (_value: number, index: number, array: number[]) => {
      return (
        index < array.length - 1 && array[index] === 0 && array[index + 1] === 1
      );
    },
  ).length;

  const countRetries = recentStatusArray?.filter(
    (value: number) => value === 0.5,
  ).length;

  let status;
  let color;

  if (!recentStatusArray || recentStatusArray.length < 5) {
    status = 'Not Enough Data';
    color = 'gray';
  } else if (lastTwoEntries.every((value: number) => value === 0)) {
    status = 'Broken';
    color = 'red';
  } else if (countRetries >= 4) {
    status = 'Frequent Retries';
    color = 'orange';
  } else if (countFailures >= 2) {
    status = 'Unreliable';
    color = 'red';
  } else {
    status = 'Reliable';
    color = 'green';
  }
  return (
    <Chip label={status} color="primary" style={{ backgroundColor: color }} />
  );
};

const renderRate = (rowData: any): JSX.Element => {
  const { passRate } = rowData;
  const percentage = passRate * 100;
  let status;
  if (percentage >= 90) {
    status = <StatusOK />;
  } else if (percentage >= 80) {
    status = <StatusWarning />;
  } else {
    status = <StatusError />;
  }

  return (
    <div>
      {status} {`${percentage.toFixed(2)}%`}
    </div>
  );
};

const ActionCenterPanel = ({ allInfo }: Props) => {
  const { entity } = useEntity();
  const displayName = entity.metadata.name;
  const repo = entity.metadata.annotations?.['gitlab.com/project-slug']
    .split('/')
    .pop();

  useEffect(() => {
    window.sessionStorage.setItem(
      'testComponentDisplayName',
      displayName ? displayName : '',
    );
  }, [displayName]);

  const mappedData = useMemo(() => {
    if (!Array.isArray(allInfo)) {
      return [];
    }

    const filteredData = allInfo.filter(res => {
      const { recentPassArray } = res;
      const arrayValue = recentPassArray.split(',');
      const recentStatusArray = arrayValue?.reverse().map(Number);
      const lastTwoEntries = recentStatusArray.slice(-2);

      const countFailures = recentStatusArray?.filter(
        (_value: number, index: number, array: number[]) => {
          return (
            index < array.length - 1 &&
            array[index] === 0 &&
            array[index + 1] === 1
          );
        },
      ).length;

      const countRetries = recentStatusArray?.filter(
        (value: number) => value === 0.5,
      ).length;

      let status;

      if (!recentStatusArray || recentStatusArray.length < 5) {
        status = 'Not Enough Data';
      } else if (lastTwoEntries.every((value: number) => value === 0)) {
        status = 'Broken';
      } else if (countRetries >= 4) {
        status = 'Frequent Retries';
      } else if (countFailures >= 2) {
        status = 'Unreliable';
      } else {
        status = 'Reliable';
      }

      // Filter out 'Not Enough Data', and 'Reliable' tests
      const isNotEnoughData = status === 'Not Enough Data';
      const isReliable = status === 'Reliable';
      return !isNotEnoughData && !isReliable;
    });

    filteredData.sort((a, b) => {
      const statusA = renderReliabilityScore(a).props.label.toLowerCase();
      const statusB = renderReliabilityScore(b).props.label.toLowerCase();
      return statusA.localeCompare(statusB);
    });

    return filteredData.map(res => ({
      name: res.name,
      passRate: res.passRate,
      recentPassArray: res.recentPassArray,
      avgDuration: res.avgDuration,
      namespace: repo,
    }));
  }, [allInfo, repo]);

  const customStatusFilter = (rowData: any, searchText: string) => {
    const status = renderReliabilityScore(rowData).props.label.toLowerCase();
    return status.includes(searchText.toLowerCase());
  };

  const customNameFilter = (rowData: any, searchText: string) => {
    const formattedName = formatName(rowData.name); // Transform name to formattedName
    return formattedName.toLowerCase().includes(searchText.toLowerCase());
  };

  const columns: TableColumn[] = [
    {
      title: 'Name',
      field: 'formattedName',
      render: renderLink,
      customFilterAndSearch: (filterValue: string, rowData: any) =>
        customNameFilter(rowData, filterValue),
    },
    {
      title: 'Pass Rate',
      field: 'passRate',
      render: renderRate,
    },
    {
      title: 'Average Duration',
      field: 'avgDuration',
      render: renderDuration,
    },
    {
      title: 'Status',
      field: 'recentPassArray',
      render: renderReliabilityScore,
      customFilterAndSearch: (filterValue: string, rowData: any) =>
        customStatusFilter(rowData, filterValue),
    },
  ];

  return (
    <Grid item>
      <Table
        title="Action Center"
        options={{ search: true }}
        columns={columns}
        data={mappedData || []}
      />
    </Grid>
  );
};

export default ActionCenterPanel;
