import React from 'react';
import { Box, Card, CardContent, CircularProgress } from '@material-ui/core';

const LoadingCard = () => {
  return (
    <Card style={{ marginTop: '16px' }}>
      <CardContent>
        <Box display="flex" alignContent="center" justifyContent="center" p={5}>
          <CircularProgress />
        </Box>
      </CardContent>
    </Card>
  );
};

export default LoadingCard;
