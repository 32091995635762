/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {
  ANNOTATION_EDIT_URL,
  ANNOTATION_LOCATION,
  GroupEntity,
  RELATION_CHILD_OF,
  RELATION_PARENT_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  Avatar,
  InfoCard,
  InfoCardVariants,
  Link,
} from '@backstage/core-components';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { EntityRefLink, EntityRefLinkList } from './EntityRefLink';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
} from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CachedIcon from '@material-ui/icons/Cached';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import MessageIcon from '@material-ui/icons/Message';
import Alert from '@material-ui/lab/Alert';
import React, { useCallback } from 'react';

const SLACK_ORGANIZATION = 'quantummetric';

const CardTitle = (props: { title: string }) => (
  <Box display="flex" alignItems="center">
    <GroupIcon fontSize="inherit" />
    <Box ml={1}>{props.title}</Box>
  </Box>
);

type GroupEntityWithOwner = GroupEntity & {
  spec: {
    profile: { slack?: string; owner?: string };
  };
};

/**
 *
 * Return a link which will bring you to the correct slack channel.
 *
 * Currently, this opens slack in the browser.
 *
 * Deep-linking can be done, but will require channel IDs, which would best be fetched
 * using slack integration (e.g. an api call).
 *
 * Documentation about that can be found here: https://api.slack.com/reference/deep-linking#client
 *
 * This can be taken care of at a future time.
 *
 * @param channel channel name, e.g. #dev
 * @returns a link which can be used to navigate to slack
 */
const getSlackLink = (channel: string) =>
  `https://${SLACK_ORGANIZATION}.slack.com/messages/${channel.replace(
    '#',
    '',
  )}`;

/** @public */
export const GroupProfileCard = (props: { variant?: InfoCardVariants }) => {
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const { entity: group } = useEntity<GroupEntityWithOwner>();

  const refreshEntity = useCallback(async () => {
    await catalogApi.refreshEntity(stringifyEntityRef(group));
    alertApi.post({ message: 'Refresh scheduled', severity: 'info' });
  }, [catalogApi, alertApi, group]);

  if (!group) {
    return <Alert severity="error">Group not found</Alert>;
  }

  const {
    metadata: { name, description, annotations },
    spec: { profile },
  } = group;

  const childRelations = getEntityRelations(group, RELATION_PARENT_OF, {
    kind: 'group',
  });
  const parentRelations = getEntityRelations(group, RELATION_CHILD_OF, {
    kind: 'group',
  });

  const entityLocation = annotations?.[ANNOTATION_LOCATION];
  const allowRefresh =
    entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');

  const entityMetadataEditUrl =
    group.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const displayName = profile?.displayName ?? name;
  const emailHref = profile?.email ? `mailto:${profile.email}` : '#';

  const { slack, owner } = profile;

  const infoCardAction = entityMetadataEditUrl ? (
    <IconButton
      aria-label="Edit"
      title="Edit Metadata"
      component={Link}
      to={entityMetadataEditUrl}
    >
      <EditIcon />
    </IconButton>
  ) : (
    <IconButton aria-label="Edit" disabled title="Edit Metadata">
      <EditIcon />
    </IconButton>
  );

  return (
    <InfoCard
      title={<CardTitle title={displayName} />}
      subheader={description}
      variant={props.variant}
      action={
        <>
          {allowRefresh && (
            <IconButton
              aria-label="Refresh"
              title="Schedule entity refresh"
              onClick={refreshEntity}
            >
              <CachedIcon />
            </IconButton>
          )}
          {infoCardAction}
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2} xl={1}>
          <Avatar displayName={displayName} picture={profile?.picture} />
        </Grid>
        <Grid item md={10} xl={11}>
          <List>
            {owner && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Owner">
                    <PersonIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <EntityRefLink
                      entityRef={{
                        name: owner,
                        kind: 'user',
                        namespace: 'default',
                      }}
                      defaultKind="User"
                    />
                  }
                  secondary="Owner"
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Parent Group">
                  <AccountTreeIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={
                  parentRelations.length
                    ? parentRelations.map(rel => (
                        <EntityRefLink entityRef={rel} defaultKind="Group" />
                      ))
                    : 'N/A'
                }
                secondary="Parent Group"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Child Groups">
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={
                  childRelations.length ? (
                    <EntityRefLinkList entityRefs={childRelations} />
                  ) : (
                    'N/A'
                  )
                }
                secondary="Child Groups"
              />
            </ListItem>
            <Divider />
            {slack && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Slack">
                    <MessageIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={<Link to={getSlackLink(slack)}>{slack}</Link>}
                  secondary="Slack"
                />
              </ListItem>
            )}
            {profile?.email && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Email">
                    <EmailIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={<Link to={emailHref}>{profile.email}</Link>}
                  secondary="Email"
                />
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
