export const tools = [
  {
    title: 'Monika',
    description:
      'Monika is a command line application for synthetic monitoring.',
    url: '/catalog/default/component/monika/docs',
    image: '/monika.png',
    tags: ['monitoring', 'alerting', 'observability'],
  },
  {
    title: 'ArgoCD',
    description:
      'Argo CD is a declarative, GitOps continuous delivery tool for Kubernetes.',
    url: 'https://argo-cd.sre.dev.qmengineering.com/',
    image: '/argo.png',
    tags: ['cicd'],
  },
  {
    title: 'GraphQL',
    description:
      'Integrates GraphiQL as a tool to browse GraphiQL endpoints inside Backstage.',
    url: '/graphiql',
    image: '/graphiql.png',
    tags: ['graphql', 'dev'],
  },
  {
    title: 'Spinnaker',
    description: 'Continuous Delivery and Application Management Platform',
    url: 'https://spinnaker.qmengineering.com/',
    image: '/spinnaker.png',
    tags: ['cicd'],
  },
  {
    title: 'Grafana',
    description:
      'Grafana is a multi-platform open source analytics and interactive visualization web application.',
    url: 'https://grafana.prod.qmengineering.com',
    image: '/grafana.png',
    tags: ['monitoring', 'observability'],
  },
  {
    title: 'Bloodhound',
    description:
      'An internal-facing tool to get query timing and cost by page url. Written using React and Playwright.',
    url: '/qm-bloodhound',
    image: '/bloodhound.png',
    tags: ['analytics', 'testing'],
  },
  {
    title: 'Storybook',
    description: 'Storybook for the QM Design System',
    url: 'https://storybook.dev.qmengineering.com/',
    image: '/margarita.png',
    tags: ['ux', 'design'],
  },
  {
    title: 'Feature Flags',
    description:
      'An internal-facing tool to interact with Split.io Feature Flags.',
    url: '/qm-feature-flags',
    image: '/feature-flags.png',
    tags: ['dev'],
  },
  {
    title: 'Product Permissions',
    description: 'Availability Controls for Quantum Metric Products',
    url: '/product-permissions/prod',
    image: '/product-permissions.png',
    tags: ['dev'],
  },
  {
    title: 'DXP Search',
    description: 'AI-Powered Documents Search',
    url: '/dxp-search',
    image: '/dxp-search.png',
    tags: ['dev'],
  },
];
