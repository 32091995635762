import React, { useState, createContext, useEffect } from 'react';
import { useIamProxy } from '@internal/iam-common';

import type { Instance } from '../../types';
import { toShortEnv } from '../../utiils';

export const InstanceContext = createContext<any>(null);

type ProviderProps = {
  children: any;
  env: string | null;
  serviceKey: string | null;
  instanceKey: string | null;
};

const InstanceProvider = ({
  children,
  env,
  serviceKey,
  instanceKey,
}: ProviderProps) => {
  const [instance, setInstance] = useState<Instance | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchInstance = useIamProxy();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const url = `https://config.${toShortEnv(
          env,
        )}.qmengineering.com/v1/services/${serviceKey}/instances/${instanceKey}`;
        const { data, error: proxyError } = await fetchInstance(url, {
          method: 'GET',
          env: (env || '').toLowerCase(),
          headers: {
            'Content-type': 'application/json',
          },
        });
        setInstance(data);
        setError(proxyError);
      } catch (err: any) {
        setError(err.message);
      }
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env]);

  return (
    <InstanceContext.Provider
      value={{
        env,
        instance,
        error,
        loading,
      }}
    >
      {children}
    </InstanceContext.Provider>
  );
};
export default InstanceProvider;
