import React, { useContext } from 'react';
import { Link, Tooltip, Typography } from '@material-ui/core';
import { Table, TableColumn } from '@backstage/core-components';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { ServiceContext } from '../Context';

type TableComponentProps = {
  serviceKey: string;
  env: string;
};

export const InstanceTable = ({ env }: TableComponentProps) => {
  const { instances } = useContext(ServiceContext);

  const formatDate = (date: Date) => {
    return date.toLocaleString('en-EN', {
      dateStyle: 'short',
      timeStyle: 'long',
    });
  };

  const columns: TableColumn[] = [
    {
      title: 'Key',
      field: 'key',
      render: (rowData: any) => {
        const { key, service_key } = rowData;
        return (
          <Link
            href={`/qm-config/services/${env}/${service_key}/instances/${key}`}
          >
            {key}
          </Link>
        );
      },
    },
    {
      title: 'Verson',
      field: 'version',
      render: (rowData: any) => {
        const version = rowData.version;
        return <Typography>{version}</Typography>;
      },
    },
    {
      title: 'Service Version',
      field: 'service_version',
      render: (rowData: any) => {
        const version = rowData.service_version;
        return <Typography>{version}</Typography>;
      },
    },
    { title: 'Author', field: 'author' },
    {
      title: 'Created',
      field: 'created_at',
      render: (rowData: any) => {
        return formatDate(new Date(rowData.created_at));
      },
    },
    {
      title: 'Updated',
      field: 'updated_at',
      render: (rowData: any) => {
        return formatDate(new Date(rowData.created_at));
      },
    },
    {
      title: 'Actions',
      field: 'actions',
      render: () => {
        return (
          <>
            <Tooltip title="View">
              <OpenInNew fontSize="small" color="action" />
            </Tooltip>
            <Tooltip title="Edit">
              <Edit fontSize="small" color="action" />
            </Tooltip>
            <Tooltip title="Delete">
              <Delete fontSize="small" color="action" />
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <Table
      title={`Instances (${instances.length})`}
      options={{ pageSize: 10, padding: 'dense' }}
      columns={columns}
      data={instances}
    />
  );
};
