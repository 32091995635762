import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  PipelineTypeMapping,
  PIPELINE_TYPE_MAPPING,
} from '../../utils/pipelineTypes';

export type PipelineTypeContextType = [
  PipelineTypeMapping,
  React.Dispatch<React.SetStateAction<PipelineTypeMapping>>,
];

export const PipelineTypeContext = createContext<PipelineTypeContextType>([
  PIPELINE_TYPE_MAPPING.MERGE_TRAIN,
  () => {},
]);

export const usePipelineType = (): PipelineTypeContextType => {
  const context = useContext(PipelineTypeContext);
  if (!context) {
    throw new Error(
      'usePipelineType must be used within a PipelineTypeProvider',
    );
  }
  return context;
};

type Properties = {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
};

export const PipelineTypeProvider = ({ children }: Properties) => {
  const [pipelineType, setPipelineType] = useState<PipelineTypeMapping>(() => {
    const storedValue = localStorage.getItem('pipelineType');
    return storedValue
      ? (storedValue as PipelineTypeMapping)
      : PIPELINE_TYPE_MAPPING.MERGE_TRAIN;
  });

  useEffect(() => {
    localStorage.setItem('pipelineType', pipelineType);
  }, [pipelineType]);

  return (
    <PipelineTypeContext.Provider value={[pipelineType, setPipelineType]}>
      {children}
    </PipelineTypeContext.Provider>
  );
};
