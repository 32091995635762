import React from 'react';
import { Grid } from '@material-ui/core';
import { InstanceOverviewCard } from './InstanceOverviewCard';
import { InstanceBodyCard } from './InstanceBodyCard';

export const InstanceOverviewLayout = () => {
  return (
    <>
      <Grid container spacing={3} direction="column">
        <Grid container item direction="row">
          <Grid item xs={6} md={6}>
            <InstanceOverviewCard />
          </Grid>
        </Grid>
        <Grid container item direction="row">
          <Grid item xs={12} md={12}>
            <InstanceBodyCard />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InstanceOverviewLayout;
