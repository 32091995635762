import { Button } from '@backstage/core-components';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Button as MuiButton,
  CardMedia,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { fetchResults } from '../api';
import { DEFAULT_SERVICE, getGradient } from '../util';
import { Health } from '../types';
import {
  useRouteRefParams,
  useApi,
  configApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from '../routes';

export const OxOverviewCard = () => {
  const [score, setScore] = useState<Health>();
  const [apiFailed, setApiFailed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const routeParams = useRouteRefParams<{ name: string }>(rootRouteRef);
  const config = useApi(configApiRef);

  const runAPI = () => {
    setApiFailed(() => false);
    fetchResults(config, routeParams?.name ?? DEFAULT_SERVICE).then(res => {
      if (res) {
        setScore(res.ox_score);
      } else {
        setApiFailed(true);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    runAPI();
    // Shutup react, compDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderScore = () => {
    if (isLoading) return <CircularProgress style={{ color: 'white' }} />;
    return (
      <Typography variant="h2" style={{ color: 'white' }}>
        {apiFailed ? 'Unable to retrieve score' : score}
      </Typography>
    );
  };

  return (
    <Card>
      <CardMedia>
        <Box
          style={{
            color: 'white',
            backgroundImage: getGradient(score || 'unknown'),
            textTransform: 'capitalize',
            position: 'relative',
          }}
          p={3}
        >
          <Typography variant="subtitle2"> Excellence Score</Typography>
          <br />
          {renderScore()}
          {apiFailed ? (
            <MuiButton
              color="secondary"
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                padding: 20,
              }}
              onClick={runAPI}
            >
              Retry
            </MuiButton>
          ) : (
            <Button
              to="excellence"
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                padding: 20,
                color: 'white',
              }}
            >
              Score Details
            </Button>
          )}
        </Box>
      </CardMedia>
    </Card>
  );
};
