import React, { useEffect } from 'react';
import { Chip, Grid } from '@material-ui/core';
import {
  Link,
  Table,
  TableColumn,
  StatusOK,
  StatusWarning,
  StatusError,
} from '@backstage/core-components';
import type { Result } from '../../utils/types';
import { useEntity } from '@backstage/plugin-catalog-react';
import { formatName } from '../../utils/fns';

type Props = {
  allInfo: Result[];
};

const renderLink = (rowData: any): JSX.Element => {
  const { name, namespace } = rowData;
  const encodedName = encodeURI(name);
  const formattedName = formatName(name);
  const searchParams = new URLSearchParams(window.location.search);
  const selectedRange = searchParams.get('dateRange');
  const ctxPipelineType = searchParams.get('pipelineType');

  return (
    <Link
      to={`/qm-test-metrics/${namespace}/test/${encodedName}?dateRange=${selectedRange}&pipelineType=${ctxPipelineType}`}
    >
      {formattedName}
    </Link>
  );
};

const renderLastExecutionStatus = (rowData: any) => {
  const { recentPassArray } = rowData;
  const arrayValue = recentPassArray?.split(',');

  // Ensure that arrayValue is an array with at least one element
  if (Array.isArray(arrayValue) && arrayValue.length > 0) {
    const recentStatusArray = arrayValue.map(Number);

    let status;
    let color;

    if (recentStatusArray[0] === 1) {
      status = 'Pass';
      color = 'green';
    } else if (recentStatusArray[0] === 0.5) {
      status = 'Retry';
      color = 'orange';
    } else {
      status = 'Fail';
      color = 'red';
    }

    return (
      <Chip label={status} color="primary" style={{ backgroundColor: color }} />
    );
  }

  return <div>No Data</div>;
};

const renderDuration = (rowData: any): string => {
  const { avgDuration } = rowData;
  const minutes = Math.floor(avgDuration / 60000);
  const remainingSeconds = Math.floor((avgDuration % 60000) / 1000);
  return `${minutes}m${remainingSeconds}s`;
};

const renderRate = (rowData: any): JSX.Element => {
  const { passRate } = rowData;
  const percentage = passRate * 100;
  let status;
  if (percentage >= 90) {
    status = <StatusOK />;
  } else if (percentage >= 80) {
    status = <StatusWarning />;
  } else {
    status = <StatusError />;
  }

  return (
    <div>
      {status} {`${percentage.toFixed(2)}%`}
    </div>
  );
};

export const TestExecutionTable = ({ allInfo }: Props) => {
  const { entity } = useEntity();
  const displayName = entity.metadata.name;
  const repo = entity.metadata.annotations?.['gitlab.com/project-slug']
    .split('/')
    .pop();

  useEffect(() => {
    window.sessionStorage.setItem(
      'testComponentDisplayName',
      displayName ? displayName : '',
    );
  }, [displayName]);

  const data = allInfo?.map(res => ({
    name: res.name,
    namespace: repo,
    passRate: res.passRate,
    recentPassArray: res.recentPassArray,
    avgDuration: res.avgDuration,
  }));

  const customStatusFilter = (rowData: any, searchText: string) => {
    const status = renderLastExecutionStatus(rowData).props.label.toLowerCase();
    return status.includes(searchText.toLowerCase());
  };

  const customNameFilter = (rowData: any, searchText: string) => {
    const formattedName = formatName(rowData.name); // Transform name to formattedName
    return formattedName.toLowerCase().includes(searchText.toLowerCase());
  };

  const columns: TableColumn[] = [
    {
      title: 'Name',
      field: 'formattedName',
      render: renderLink,
      customFilterAndSearch: (filterValue: string, rowData: any) =>
        customNameFilter(rowData, filterValue),
    },
    {
      title: 'Pass Rate',
      field: 'passRate',
      render: renderRate,
    },
    {
      title: 'Duration',
      field: 'avgDuration',
      render: renderDuration,
    },
    {
      title: 'Last Execution Status',
      field: 'lastExecutionStatusLabel',
      render: renderLastExecutionStatus,
      customFilterAndSearch: (filterValue: string, rowData: any) =>
        customStatusFilter(rowData, filterValue),
    },
  ];

  return (
    <Grid item>
      <Table
        title="All Test Executions"
        options={{ pageSize: 10, search: true }}
        columns={columns}
        data={data || []}
      />
    </Grid>
  );
};

export default TestExecutionTable;
