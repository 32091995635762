import {
  fetchApiRef,
  useApi,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

interface ProxyOptions extends RequestInit {
  method: string;
  env: string;
  body?: string;
}

type Response = {
  data: any;
  loading: boolean;
  error: string | null;
};

const useIamProxy = () => {
  const { fetch } = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const fetchData = async (
    url: string,
    options: ProxyOptions,
  ): Promise<Response> => {
    const loading = false;
    let data;

    const { env } = options;
    try {
      const proxyUrl = await discoveryApi.getBaseUrl('qm-iam-proxy');

      let target: string = '';
      let body;

      if (options.method === 'GET') {
        target = `${proxyUrl}?target=${url}&env=${env}`;
      } else if (options.body) {
        target = proxyUrl;

        const parsed = JSON.parse(options.body);

        body = JSON.stringify({
          target: url,
          env,
          ...parsed,
        });
      }
      options.body = body;
      const response = await fetch(target, options);
      const responseData = await response.json();
      if (responseData.code === 401) {
        return {
          data,
          error: '401 Unauthorized',
          loading,
        };
      }

      if (responseData.error) {
        throw new Error(responseData.error.message);
      }

      return {
        data: responseData,
        error: null,
        loading,
      };
    } catch (err: any) {
      return {
        data,
        error: err.message,
        loading,
      };
    }
  };

  return fetchData;
};

export default useIamProxy;
