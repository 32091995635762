/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#fff',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      width="600"
      height="167"
      viewBox="0 0 500 167"
      fill="none"
    >
      <g clipPath="url(#clip0)">
        <g clipPath="url(#clip1)">
          <path
            d="M182.815 164.278C183.064 164.775 182.702 165.36 182.146 165.36H83.369C37.126 165.36 0 128.691 0 82.68C0 36.669 37.126 0 83.369 0C129.606 0 166.732 36.669 166.732 82.68C166.732 94.051 164.464 104.85 160.355 114.666C160.148 115.16 159.661 115.479 159.125 115.479H121.423C120.757 115.479 120.38 114.71 120.796 114.189C127.609 105.668 131.652 94.716 131.652 82.68C131.652 55.122 110.469 33.25 83.368 33.25C56.261 33.25 35.077 55.122 35.077 82.68C35.077 110.238 58.47 132.11 85.577 132.11H165.695C166.329 132.11 166.909 132.468 167.193 133.036L182.815 164.278Z"
            fill="white"
          />
          <path
            d="M199.493 110.954H215.015L222.7 138.271C223.157 139.996 223.6 141.722 224.032 143.445C224.462 145.17 224.831 146.744 225.135 148.163C225.489 149.838 225.82 151.435 226.124 152.957H226.277C226.581 151.435 226.911 149.838 227.266 148.163C227.571 146.744 227.913 145.171 228.293 143.445C228.674 141.722 229.117 139.997 229.624 138.271L237.309 110.954H253.06V165.359H242.788V134.162C242.788 133.046 242.8 131.841 242.826 130.547C242.851 129.254 242.914 128.025 243.016 126.857C243.066 125.539 243.142 124.22 243.244 122.901H243.092C242.838 124.169 242.584 125.412 242.331 126.629C242.077 127.694 241.81 128.81 241.532 129.977C241.253 131.145 240.987 132.234 240.733 133.249L231.374 165.359H220.797L211.666 133.249C211.361 132.234 211.082 131.145 210.829 129.977C210.575 128.81 210.322 127.694 210.068 126.629C209.814 125.412 209.56 124.169 209.307 122.901H209.154C209.204 124.221 209.28 125.54 209.383 126.857C209.433 128.025 209.484 129.254 209.535 130.547C209.585 131.842 209.611 133.046 209.611 134.162V165.359H199.491V110.954H199.493Z"
            fill="white"
          />
          <path
            d="M277.41 166.501C274.163 166.501 271.284 165.969 268.773 164.903C266.262 163.838 264.144 162.379 262.419 160.527C260.694 158.676 259.388 156.482 258.501 153.945C257.612 151.41 257.169 148.695 257.169 145.803C257.169 142.912 257.638 140.211 258.577 137.7C259.515 135.188 260.833 133.02 262.534 131.194C264.233 129.367 266.262 127.922 268.621 126.856C270.98 125.79 273.579 125.258 276.42 125.258C279.565 125.258 282.329 125.828 284.714 126.97C287.098 128.111 289.101 129.723 290.725 131.803C292.348 133.883 293.578 136.356 294.416 139.222C295.253 142.089 295.671 145.271 295.671 148.771H267.365C267.77 151.815 268.797 154.199 270.447 155.924C272.095 157.649 274.391 158.511 277.333 158.511C279.361 158.511 280.985 158.081 282.203 157.218C283.42 156.356 284.307 155.19 284.866 153.718H295.063C294.707 155.443 294.06 157.079 293.122 158.626C292.183 160.174 290.965 161.531 289.47 162.697C287.973 163.864 286.21 164.79 284.182 165.474C282.152 166.158 279.895 166.501 277.41 166.501ZM276.572 133.249C273.833 133.249 271.752 133.998 270.333 135.494C268.912 136.99 267.974 139.032 267.518 141.619H284.867C284.715 139.135 283.89 137.118 282.394 135.57C280.897 134.023 278.957 133.249 276.572 133.249Z"
            fill="white"
          />
          <path
            d="M295.9 126.324H301.302V114.15H311.422V126.324H318.118V133.172H311.422V153.868C311.422 155.34 311.776 156.355 312.487 156.912C313.197 157.471 314.135 157.749 315.302 157.749H317.28C317.634 157.749 317.99 157.724 318.345 157.672V165.282C317.837 165.333 317.28 165.358 316.671 165.358C316.112 165.408 315.427 165.434 314.617 165.434C313.805 165.434 312.892 165.434 311.878 165.434C310.609 165.434 309.354 165.295 308.112 165.016C306.868 164.737 305.74 164.243 304.726 163.531C303.711 162.821 302.887 161.857 302.253 160.64C301.618 159.423 301.302 157.901 301.302 156.074V133.171H295.9V126.324Z"
            fill="white"
          />
          <path
            d="M331.89 132.564H332.118C333.284 130.333 334.603 128.658 336.075 127.542C337.545 126.427 339.423 125.868 341.705 125.868C342.77 125.868 343.581 125.969 344.14 126.172V135.226H343.912C340.513 134.872 337.722 135.607 335.542 137.433C333.36 139.26 332.27 142.227 332.27 146.336V165.359H321.922V126.324H331.89V132.564Z"
            fill="white"
          />
          <path
            d="M347.108 110.954H357.456V120.237H347.108V110.954ZM347.108 126.324H357.456V165.359H347.108V126.324Z"
            fill="white"
          />
          <path
            d="M380.74 166.501C377.645 166.501 374.868 165.969 372.408 164.903C369.947 163.838 367.855 162.379 366.131 160.527C364.406 158.676 363.074 156.494 362.136 153.983C361.197 151.472 360.728 148.771 360.728 145.879C360.728 142.988 361.185 140.287 362.098 137.776C363.011 135.264 364.316 133.084 366.016 131.232C367.715 129.381 369.756 127.922 372.142 126.856C374.526 125.791 377.214 125.258 380.207 125.258C382.743 125.258 385.026 125.614 387.055 126.323C389.083 127.034 390.847 128.01 392.343 129.252C393.839 130.496 395.056 131.992 395.995 133.742C396.933 135.493 397.58 137.432 397.936 139.563H387.815C387.46 137.839 386.661 136.405 385.418 135.263C384.175 134.123 382.59 133.551 380.662 133.551C377.516 133.551 375.157 134.654 373.586 136.861C372.013 139.068 371.227 142.073 371.227 145.877C371.227 149.581 371.975 152.562 373.472 154.818C374.968 157.075 377.315 158.204 380.51 158.204C382.641 158.204 384.391 157.659 385.76 156.568C387.129 155.477 387.992 153.816 388.347 151.583H398.315C398.163 153.664 397.642 155.604 396.755 157.404C395.866 159.205 394.674 160.779 393.179 162.122C391.682 163.467 389.88 164.533 387.776 165.318C385.673 166.107 383.327 166.501 380.74 166.501Z"
            fill="white"
          />
          <path
            d="M258.033 94.28L249.145 84.39C254.03 78.597 256.351 71.018 256.351 63.775C256.351 49.205 246.887 33.27 226.194 33.27C205.501 33.27 196.037 49.206 196.037 63.775C196.037 78.344 205.419 94.28 226.194 94.28C232.424 94.28 237.622 92.839 241.858 90.439L245.352 94.28H258.033ZM226.194 83.619C214.068 83.619 207.749 73.769 207.749 63.774C207.749 53.779 213.958 44.436 226.194 44.436C238.931 44.436 244.639 53.78 244.639 63.774C244.639 68.08 243.638 72.357 241.456 75.833L237.492 71.422H224.557L234.244 82.07C231.974 83.056 229.304 83.619 226.194 83.619Z"
            fill="white"
          />
          <path
            d="M286.555 90.979V86.413H286.326C285.006 88.189 283.498 89.571 281.798 90.561C280.098 91.549 277.879 92.045 275.14 92.045C270.776 92.045 267.429 90.778 265.095 88.24C262.761 85.704 261.595 82.279 261.595 77.967V51.942H271.868V76.216C271.868 78.499 272.388 80.25 273.428 81.467C274.467 82.684 276.103 83.293 278.337 83.293C280.772 83.293 282.686 82.469 284.082 80.82C285.477 79.173 286.175 77.053 286.175 74.466V51.942H296.524V90.979H286.555Z"
            fill="white"
          />
          <path
            d="M326.276 90.979C325.972 90.574 325.717 89.965 325.515 89.152C325.312 88.342 325.16 87.505 325.058 86.641H324.906C324.398 87.402 323.827 88.1 323.194 88.734C322.559 89.369 321.798 89.913 320.911 90.37C320.023 90.827 318.971 91.195 317.753 91.473C316.536 91.752 315.089 91.892 313.416 91.892C309.408 91.892 306.135 90.877 303.6 88.848C301.063 86.82 299.795 83.852 299.795 79.945C299.795 77.814 300.2 76.014 301.013 74.542C301.823 73.071 302.94 71.854 304.361 70.89C305.781 69.927 307.443 69.178 309.346 68.645C311.248 68.113 313.29 67.694 315.471 67.389C318.717 66.933 321.102 66.451 322.624 65.943C324.146 65.436 324.907 64.447 324.907 62.975C324.907 61.556 324.361 60.426 323.271 59.589C322.18 58.753 320.544 58.333 318.363 58.333C315.928 58.333 314.102 58.778 312.884 59.665C311.667 60.554 310.981 61.936 310.829 63.813H301.622C301.672 62.089 302.052 60.453 302.763 58.905C303.473 57.358 304.513 56.001 305.883 54.833C307.253 53.667 308.977 52.729 311.058 52.018C313.137 51.308 315.597 50.953 318.439 50.953C324.272 50.953 328.47 52.209 331.033 54.72C333.594 57.231 334.876 60.769 334.876 65.335V85.196C334.876 88.139 335.333 89.94 336.246 90.599V90.979H326.276V90.979ZM315.927 84.663C317.398 84.663 318.705 84.448 319.846 84.016C320.987 83.585 321.951 83.027 322.738 82.342C323.524 81.657 324.12 80.871 324.526 79.983C324.931 79.096 325.135 78.17 325.135 77.205V71.346C324.222 71.906 323.119 72.349 321.825 72.678C320.531 73.01 319.225 73.325 317.906 73.629C315.167 74.238 313.137 74.949 311.819 75.76C310.499 76.572 309.84 77.891 309.84 79.717C309.84 81.544 310.398 82.826 311.514 83.561C312.629 84.296 314.101 84.663 315.927 84.663Z"
            fill="white"
          />
          <path
            d="M350.018 57.268H350.246C351.565 55.138 353.15 53.539 355.002 52.474C356.854 51.409 359.123 50.876 361.813 50.876C363.841 50.876 365.681 51.245 367.33 51.979C368.978 52.715 370.386 53.729 371.553 55.023C372.72 56.317 373.633 57.851 374.293 59.626C374.952 61.403 375.282 63.356 375.282 65.485V90.977H364.933V67.007C364.933 64.876 364.349 63.152 363.183 61.832C362.016 60.514 360.342 59.854 358.161 59.854C355.878 59.854 353.987 60.679 352.492 62.327C350.995 63.975 350.247 66.144 350.247 68.833V90.977H339.898V51.942H350.019V57.268H350.018Z"
            fill="white"
          />
          <path
            d="M377.108 51.942H382.511V39.767H392.632V51.942H399.328V58.791H392.632V79.488C392.632 80.96 392.986 81.975 393.697 82.532C394.407 83.091 395.345 83.369 396.513 83.369H398.491C398.845 83.369 399.201 83.344 399.556 83.292V90.902C399.048 90.953 398.491 90.978 397.882 90.978C397.323 91.028 396.638 91.054 395.828 91.054C395.015 91.054 394.103 91.054 393.088 91.054C391.819 91.054 390.564 90.915 389.321 90.636C388.077 90.357 386.949 89.863 385.935 89.151C384.92 88.441 384.096 87.477 383.462 86.26C382.827 85.043 382.511 83.521 382.511 81.694V58.791H377.108V51.942V51.942Z"
            fill="white"
          />
          <path
            d="M427.786 90.979V86.413H427.557C426.237 88.189 424.729 89.571 423.029 90.561C421.329 91.549 419.11 92.045 416.371 92.045C412.007 92.045 408.66 90.778 406.326 88.24C403.992 85.704 402.826 82.279 402.826 77.967V51.942H413.1V76.216C413.1 78.499 413.62 80.25 414.66 81.467C415.699 82.684 417.335 83.293 419.569 83.293C422.004 83.293 423.918 82.469 425.314 80.82C426.709 79.173 427.407 77.053 427.407 74.466V51.942H437.756V90.979H427.786Z"
            fill="white"
          />
          <path
            d="M442.929 51.942H452.898V57.192H453.126C454.191 55.417 455.662 53.921 457.539 52.703C459.415 51.486 461.725 50.876 464.464 50.876C466.949 50.876 469.131 51.498 471.008 52.741C472.884 53.984 474.305 55.645 475.27 57.725H475.422C476.74 55.594 478.401 53.92 480.406 52.703C482.41 51.486 484.655 50.876 487.141 50.876C491.25 50.876 494.42 52.106 496.653 54.566C498.885 57.027 500.001 60.414 500.001 64.725V90.978H489.652V66.476C489.652 64.245 489.131 62.57 488.092 61.454C487.052 60.339 485.568 59.78 483.641 59.78C481.459 59.78 479.747 60.554 478.504 62.101C477.26 63.649 476.64 65.715 476.64 68.302V90.978H466.29V66.476C466.29 64.245 465.769 62.57 464.73 61.454C463.689 60.339 462.206 59.78 460.278 59.78C458.147 59.78 456.447 60.554 455.179 62.101C453.911 63.649 453.277 65.715 453.277 68.302V90.978H442.928V51.942H442.929Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="500" height="166.501" fill="white" />
        </clipPath>
        <clipPath id="clip1">
          <rect width="500" height="166.501" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoFull;
