import React, { useState, createContext, useEffect } from 'react';
import { useIamProxy } from '@internal/iam-common';

import type { Service, Instance } from '../../types';
import { toShortEnv } from '../../utiils';

export const ServiceContext = createContext<any>(null);

type ProviderProps = {
  children: any;
  env: string | null;
  serviceKey: string | null;
};

const ServiceProvider = ({ children, env, serviceKey }: ProviderProps) => {
  const [service, setService] = useState<Service | null>(null);
  const [instances, setIntances] = useState<Array<Instance>>([]);
  const [versions, setVersions] = useState<Array<Service>>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useIamProxy();

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const url = `https://config.${toShortEnv(
          env,
        )}.qmengineering.com/v1/services/${serviceKey}`;
        const { data, error: proxyError } = await fetchData(url, {
          method: 'GET',
          env: (env || '').toLowerCase(),
          headers: {
            'Content-type': 'application/json',
          },
        });
        setService(data);
        setError(proxyError);
      } catch (err: any) {
        setError(err.message);
      }
      setLoading(false);
    };

    const fetchInstances = async () => {
      setLoading(true);
      try {
        const url = `https://config.${toShortEnv(
          env,
        )}.qmengineering.com/v1/services/${serviceKey}/instances`;
        const { data, error: proxyError } = await fetchData(url, {
          method: 'GET',
          env: (env || '').toLowerCase(),
          headers: {
            'Content-type': 'application/json',
          },
        });
        const results = data && data.results ? data.results : [];
        setIntances(results);
        setError(proxyError);
      } catch (err: any) {
        setError(err.message);
      }
      setLoading(false);
    };

    const fetchVersions = async () => {
      setLoading(true);
      try {
        const url = `https://config.${toShortEnv(
          env,
        )}.qmengineering.com/v1/services/${serviceKey}/versions`;
        const { data, error: proxyError } = await fetchData(url, {
          method: 'GET',
          env: (env || '').toLowerCase(),
          headers: {
            'Content-type': 'application/json',
          },
        });
        const results = data && data.results ? data.results : [];
        setVersions(results);
        setError(proxyError);
      } catch (err: any) {
        setError(err.message);
      }
      setLoading(false);
    };

    fetchServices();
    fetchInstances();
    fetchVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env, serviceKey]);

  return (
    <ServiceContext.Provider
      value={{
        env,
        service,
        instances,
        versions,
        error,
        loading,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
export default ServiceProvider;
