import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { FeatureFlags } from './flags';

const featureFlags = Object.entries(FeatureFlags).map(([_name, value]) => ({
  name: value,
}));

export const dxpSearchPlugin = createPlugin({
  id: 'dxp-search',
  routes: {
    root: rootRouteRef,
  },
  featureFlags,
});

export const DxpSearchPage = dxpSearchPlugin.provide(
  createRoutableExtension({
    name: 'DxpSearchPage',
    component: () =>
      import('./components/DxpSearchPage').then(m => m.DxpSearchPage),
    mountPoint: rootRouteRef,
  }),
);
