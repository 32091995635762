export type PipelineType = 'train' | 'all';

export const PIPELINE_TYPE: { [key: string]: PipelineType } = {
  MERGE_TRAIN: 'train',
  ALL: 'all',
};

export type PipelineTypeMapping = 'train' | 'all';

export const PIPELINE_TYPE_MAPPING: { [key: string]: PipelineTypeMapping } = {
  MERGE_TRAIN: 'train',
  ALL: 'all',
};

export const SELECTOR_ITEMS: {
  label: String;
  value: PipelineTypeMapping;
}[] = [
  {
    label: 'Merge Train',
    value: PIPELINE_TYPE_MAPPING.MERGE_TRAIN,
  },
  {
    label: 'All Results',
    value: PIPELINE_TYPE_MAPPING.ALL,
  },
];
