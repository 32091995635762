import React from 'react';
import { EmptyState } from '@backstage/core-components';
import { Button, Grid, Typography } from '@material-ui/core';

export const EmptyStateComponent = () => {
  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item>
        <EmptyState
          missing="info"
          title="Whoops - No Test Data Was Found!"
          description={
            <>
              <div style={{ marginBottom: '2ch' }}>
                <Typography>
                  There could be a couple of reasons for this - let's walk
                  through some steps and make sure you can get what you need.
                  The two most likely scenarios are that either:
                </Typography>
              </div>
              <div style={{ marginBottom: '2ch' }}>
                <Typography>
                  1.) Test Data is properly linked to this tool, but there is no
                  data for the selected date range. Try a longer date range from
                  the dropdown above.
                </Typography>
              </div>
              <div style={{ marginBottom: '2ch' }}>
                <Typography>
                  2.) Test Data is not properly linked to this tool. Please
                  refer to the guide below to learn how to add data to the tool!
                </Typography>
              </div>
            </>
          }
          action={
            <Button
              color="primary"
              href="/catalog/default/component/test-metric-api/docs/guides/test-metrics"
              onClick={() => {}}
              variant="contained"
            >
              GO TO GUIDE
            </Button>
          }
        />
      </Grid>
    </Grid>
  );
};

export default EmptyStateComponent;
