import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  HomePageRecentlyVisited,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { AnnouncementsCard } from '@internal/plugin-qm-announcements';
import { SubscriptionsCard } from '@internal/plugin-qm-ephemeral-subscriptions';

export const QuickPanel = () => {
  /* We will shortly compose a pretty homepage here. */
  return (
    <>
      <Grid
        style={{ margin: 5 }}
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
      >
        <Grid item xs={5}>
          <AnnouncementsCard max={2} />
        </Grid>
        <Grid item xs={5}>
          <SubscriptionsCard />
        </Grid>
      </Grid>
      <Grid
        style={{ margin: 5 }}
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
      >
        <Grid item xs={5}>
          <HomePageRecentlyVisited />
        </Grid>
        <Grid item xs={5}>
          <HomePageStarredEntities />
        </Grid>
      </Grid>
    </>
  );
};
