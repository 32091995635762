import React from 'react';
import {
  HorizontalScrollGrid,
  ItemCardHeader,
} from '@backstage/core-components';
import { Card, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

type GuideProps = {
  title: string;
};

const useStyles = makeStyles({
  header: {
    textAlign: 'center',
  },
  card: {
    margin: 20,
    width: 400,
    minWidth: 300,
    opacity: 0.7,
  },
});

const GoldenPath = (props: GuideProps) => {
  const classes = useStyles();
  const { title } = props;
  return (
    <div className={classes.card}>
      <Card>
        <CardMedia>
          <ItemCardHeader title={title} classes={{ root: classes.header }} />
        </CardMedia>
      </Card>
    </div>
  );
};

export const GoldenPaths = () => {
  return (
    <HorizontalScrollGrid scrollStep={300}>
      <GoldenPath title="CI/CD" />
      <GoldenPath title="Observability" />
      <GoldenPath title="Client & UI" />
    </HorizontalScrollGrid>
  );
};
