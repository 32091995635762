import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { FeatureFlags } from './flags';

const featureFlags = Object.entries(FeatureFlags).map(([_name, value]) => ({
  name: value,
}));

export const qmConfigPlugin = createPlugin({
  id: 'qm-config',
  routes: {
    root: rootRouteRef,
  },
  featureFlags,
});

export const QmConfigPage = qmConfigPlugin.provide(
  createRoutableExtension({
    name: 'QmConfigPage',
    component: () =>
      import('./components/ConfigurationPage').then(m => m.ConfigurationPage),
    mountPoint: rootRouteRef,
  }),
);

export const QmServicePage = qmConfigPlugin.provide(
  createRoutableExtension({
    name: 'QmServicePage',
    component: () =>
      import('./components/ServicePage').then(m => m.ServicePage),
    mountPoint: rootRouteRef,
  }),
);

export const QmInstancePage = qmConfigPlugin.provide(
  createRoutableExtension({
    name: 'QmInstancePage',
    component: () =>
      import('./components/InstancePage').then(m => m.InstancePage),
    mountPoint: rootRouteRef,
  }),
);

export const QmCreateServicePage = qmConfigPlugin.provide(
  createRoutableExtension({
    name: 'QmCreateServicePage',
    component: () =>
      import('./components/CreateServicePage').then(m => m.CreateServicePage),
    mountPoint: rootRouteRef,
  }),
);
