import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const productFlagsPlugin = createPlugin({
  id: 'product-permissions',
  routes: {
    root: rootRouteRef,
  },
});

export const ProductFlagsPage = productFlagsPlugin.provide(
  createRoutableExtension({
    name: 'ProductFlagsPage',
    component: () =>
      import('./components/ProductFlagsPage').then(m => m.ProductFlagsPage),
    mountPoint: rootRouteRef,
  }),
);
