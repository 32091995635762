import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { TestMetricOverview } from './components/TestMetricOverview/TestMetricOverview';

export const qmTestMetricsPlugin = createPlugin({
  id: 'qm-test-metrics',
  routes: {
    root: rootRouteRef,
  },
});

export const QmTestMetricDetailPage = qmTestMetricsPlugin.provide(
  createRoutableExtension({
    name: 'QmTestMetricDetailPage',
    component: () =>
      import('./components/TestMetricDetail/TestMetricDetailPage').then(
        m => m.TestMetricDetailPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export { TestMetricOverview };
