import React from 'react';
import { Accordion, Box, Link } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckMarkIcon from '@material-ui/icons/CheckCircle';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

interface Props {
  title: string;
  innerText: string;
  href: string;
}

const DropDownBullet: React.FC<Props> = props => {
  const { title, innerText, href } = props;
  return (
    <Accordion style={{ marginTop: '8px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <span style={{ marginRight: '8px' }}>
          <CheckMarkIcon style={{ color: 'grey' }} />
        </span>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column">
          <Typography>{innerText}</Typography>
          <Link href={href}>Help me improve this...</Link>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default DropDownBullet;
