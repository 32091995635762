import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const qmFeatureFlagsPlugin = createPlugin({
  id: 'qm-feature-flags',
  routes: {
    root: rootRouteRef,
  },
});

export const QmFeatureFlagListPage = qmFeatureFlagsPlugin.provide(
  createRoutableExtension({
    name: 'QmFeatureFlagListPage',
    component: () =>
      import('./components/FeatureFlagsList/FeatureFlagListPage').then(
        m => m.FeatureFlagListPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const QmFeatureFlagDetailPage = qmFeatureFlagsPlugin.provide(
  createRoutableExtension({
    name: 'QmFeatureFlagDetailPage',
    component: () =>
      import('./components/FeatureFlagsDetails/FeatureFlagDetailPage').then(
        m => m.FeatureFlagDetailPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
