import React from 'react';
import { GoldenPaths } from './GoldenPaths';
import { PanelHeader } from './PanelHeader';

export const GoldenPathPanel = () => {
  return (
    <div>
      <PanelHeader title="Golden Paths" note="Coming soon!" />
      <div style={{ padding: '10px' }}>
        <GoldenPaths />
      </div>
    </div>
  );
};
