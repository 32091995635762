import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of date range object
interface DateRange {
  startDate: string;
  endDate: string;
  timeRange: string;
}

// Define the context type
interface DateRangeContextType {
  dateRange: DateRange;
  setRange: (range: DateRange) => void;
}

// Create the context
const DateRangeContext = createContext<DateRangeContextType | undefined>(
  undefined,
);

// Custom hook to use the context
export const useDateRange = () => {
  const context = useContext(DateRangeContext);
  if (!context) {
    throw new Error(
      'useDateRange must be used within a DateRangeContextProvider',
    );
  }
  return context;
};

// DateRangeContextProvider component
interface DateRangeContextProviderProps {
  children: ReactNode;
}

export function DateRangeContextProvider({
  children,
}: DateRangeContextProviderProps) {
  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: formattedToday,
    endDate: formattedToday,
    timeRange: '',
  });

  const setRange = (range: DateRange) => {
    setDateRange(range);
  };

  return (
    <DateRangeContext.Provider value={{ dateRange, setRange }}>
      {children}
    </DateRangeContext.Provider>
  );
}
