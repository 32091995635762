import React from 'react';
import { TestMetricOverviewLayout } from './TestMetricOverviewLayout';
import { DateRangeContextProvider } from '../Shared/DateRangeContext';
import { PipelineTypeProvider } from '../Shared/PipelineTypeProvider';

export const TestMetricOverview = () => {
  return (
    <PipelineTypeProvider>
      <DateRangeContextProvider>
        <TestMetricOverviewLayout />
      </DateRangeContextProvider>
    </PipelineTypeProvider>
  );
};
