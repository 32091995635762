import { HomePageSearchBar } from '@backstage/plugin-search';
import { makeStyles, Grid } from '@material-ui/core';
import { useSearchModal } from '@backstage/plugin-search';
import React from 'react';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: '8px 0',
    borderRadius: '50px',
    margin: '25px 0 25px 0',
    cursor: 'pointer !important',
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  input: {
    cursor: 'pointer !important',
  },
}));

export const HomeSearchBar = () => {
  const classes = useStyles();
  const { toggleModal } = useSearchModal();

  return (
    <Grid container justifyContent="center" spacing={6}>
      <Grid container item xs={6} alignItems="center" direction="row">
        <HomePageSearchBar
          placeholder="Search"
          classes={{ root: classes.searchBar }}
          InputProps={{ classes: { notchedOutline: classes.searchBarOutline } }}
          clearButton={false}
          inputProps={{ className: classes.input }}
          onClick={toggleModal}
        />
      </Grid>
    </Grid>
  );
};
