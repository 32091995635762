import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const qmBloodhoundPlugin = createPlugin({
  id: 'qm-bloodhound',
  routes: {
    root: rootRouteRef,
  },
});

export const QmBloodhoundPage = qmBloodhoundPlugin.provide(
  createRoutableExtension({
    name: 'QmBloodhoundPage',
    component: () =>
      import('./components/BloodhoundComponent').then(
        m => m.BloodhoundComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
