/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#fff',
  },
});
const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="167"
      viewBox="0 0 500 167"
      fill="none"
    >
      <g clipPath="url(#clip0)">
        <g clipPath="url(#clip1)">
          <path
            d="M182.815 164.278C183.064 164.775 182.702 165.36 182.146 165.36H83.369C37.126 165.36 0 128.691 0 82.68C0 36.669 37.126 0 83.369 0C129.606 0 166.732 36.669 166.732 82.68C166.732 94.051 164.464 104.85 160.355 114.666C160.148 115.16 159.661 115.479 159.125 115.479H121.423C120.757 115.479 120.38 114.71 120.796 114.189C127.609 105.668 131.652 94.716 131.652 82.68C131.652 55.122 110.469 33.25 83.368 33.25C56.261 33.25 35.077 55.122 35.077 82.68C35.077 110.238 58.47 132.11 85.577 132.11H165.695C166.329 132.11 166.909 132.468 167.193 133.036L182.815 164.278Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="500" height="166.501" fill="white" />
        </clipPath>
        <clipPath id="clip1">
          <rect width="500" height="166.501" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
