import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { useEffect } from 'react';

export type QMApiUserIdentifier = {
  QuantumMetricAPI: UserIdentifier;
};

export type UserIdentifier = {
  identifyUser: (email: string) => void;
};

export function useUserEmail(): string | undefined {
  const identityApi = useApi(identityApiRef);
  const state = useAsync(() => identityApi.getProfileInfo(), [identityApi]);
  return state.value?.email;
}

export function useQMApiUserIdentifier(): UserIdentifier | undefined {
  if (window.hasOwnProperty('QuantumMetricAPI')) {
    return (window as unknown as QMApiUserIdentifier).QuantumMetricAPI;
  }
  return undefined;
}

export function useQMIdentityAPI() {
  const userEmail = useUserEmail();
  const userIdentifier = useQMApiUserIdentifier();

  useEffect(() => {
    if (userEmail && userIdentifier) {
      userIdentifier.identifyUser(userEmail);
    }
  }, [userEmail, userIdentifier]);
}
