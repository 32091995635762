import * as React from 'react';
import { Link } from '@backstage/core-components';
import {
  GitLabIcon,
  GCPIcon,
  GrafanaIcon,
  SpinnakerIcon,
  AtlassianIcon,
} from '../../assets/icons';
import { List, ListItemIcon, ListItemText, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  toolkit: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  tool: {
    margin: theme.spacing(0.5, 1),
  },
  label: {
    marginTop: theme.spacing(1),
    width: '72px',
    fontSize: '0.9em',
    lineHeight: '1.25',
    overflowWrap: 'break-word',
    color: theme.palette.text.secondary,
  },
  icon: {
    width: '64px',
    height: '64px',
    borderRadius: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.default,
  },
}));

const Toolkit = () => {
  const classes = useStyles();

  const tools = [
    {
      label: 'GitLab',
      url: 'https://gitlab.quantummetric.com',
      icon: <GitLabIcon />,
    },
    {
      label: 'GCP',
      url: 'https://console.cloud.google.com/',
      icon: <GCPIcon />,
    },
    {
      label: 'Atlassian',
      url: 'https://team.atlassian.com/your-work',
      icon: <AtlassianIcon />,
    },
    {
      label: 'Grafana',
      url: 'https://grafana.prod.qmengineering.com/',
      icon: <GrafanaIcon />,
    },
    {
      label: 'Spinnaker',
      url: 'https://spinnaker.qmengineering.com',
      icon: <SpinnakerIcon />,
    },
  ];

  return (
    <Grid container justifyContent="center" spacing={6}>
      <Grid
        container
        item
        xs={6}
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <List className={classes.toolkit}>
          {tools.map((tool: any) => (
            <Link key={tool.url} to={tool.url} className={classes.tool}>
              <ListItemIcon className={classes.icon}>{tool.icon}</ListItemIcon>
              <ListItemText
                secondaryTypographyProps={{ className: classes.label }}
                secondary={tool.label}
              />
            </Link>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default Toolkit;
