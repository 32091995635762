import React from 'react';
import Chip from '@material-ui/core/Chip';
import { Health } from '../types';
import { getGradient } from '../util';

interface Props {
  health: Health;
}

export const Pill: React.FC<Props> = ({ health }) => (
  <Chip
    label={health}
    style={{
      backgroundImage: getGradient(health),
      color: 'white',
      textTransform: 'capitalize',
    }}
  />
);
