import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CheckMarkIcon from '@material-ui/icons/CheckCircle';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { FailResult, Health, Result } from '../types';
import { Pill } from './Pill';
import DropDownBullet from './DropDownBullet';
import LoadingCard from './LoadingCard';

interface Props {
  namespace: string;
  health: Health;
  isLoading: boolean;
  passResults: Result[];
  failResults: Result[];
}

const DetailCard: React.FC<Props> = ({
  namespace,
  health,
  isLoading,
  passResults,
  failResults,
}) => {
  if (isLoading) {
    return <LoadingCard />;
  }
  return (
    <Card style={{ marginTop: '16px' }}>
      <CardContent>
        <Box display="flex" alignItems="center" gridGap="12px">
          <Typography variant="h3" style={{ textTransform: 'capitalize' }}>
            {namespace}
          </Typography>
          <Pill health={health} />
        </Box>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '20px',
            marginTop: '12px',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="start"
          >
            <Typography variant="h3">Focus On...</Typography>
            <List style={{ width: '100%' }}>
              {failResults.map(result => {
                const failResult = result as FailResult;
                if (failResult.remedy) {
                  const { summary, description, fact_name, action } =
                    failResult.remedy;
                  return (
                    <DropDownBullet
                      key={fact_name}
                      title={summary}
                      innerText={description}
                      href={action}
                    />
                  );
                }
                return null;
              })}
            </List>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
          >
            {passResults.length ? (
              <>
                <Typography variant="h3" style={{ alignSelf: 'center' }}>
                  Great Job!
                </Typography>
                <List>
                  {passResults.map((result, index) => {
                    return (
                      <ListItem key={`${result.fact.entity_id}_${index}`}>
                        <ListItemIcon>
                          <CheckMarkIcon style={{ color: 'green' }} />
                        </ListItemIcon>
                        <ListItemText>{result.fact.description}</ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : null}
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};

export default DetailCard;
