import React from 'react';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import DataArrayOutlinedIcon from '@mui/icons-material/DataArrayOutlined';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';

type Props = {
  dialogOpen: boolean;
  onClose: () => void;
};

export const HelpDialogComponent = ({ dialogOpen, onClose }: Props) => {
  return (
    <Dialog open={dialogOpen} onClose={onClose}>
      <DialogContent>
        <DialogTitle>Test Metrics Quick Start</DialogTitle>
        <DialogContentText>
          The test metric plugin visualizes stored data about automated test
          health. This data is collected from Gitlab by Maz and stored by the
          test-metric-api in a cloudsql database.
        </DialogContentText>
        <DialogTitle>Calculations Reference:</DialogTitle>
        <DialogContentText>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <DataArrayOutlinedIcon fontSize="small" color="disabled" />
            <Typography>
              &nbsp;Not Enough Data: Is set if an array of the 20 most recent
              results is not empty but has fewer than five entries.
            </Typography>
          </div>
          <br />
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <DangerousOutlinedIcon fontSize="small" color="error" />
            <Typography>
              &nbsp;Broken: Is set if an array of the 2 most recent results are
              failures
            </Typography>
          </div>
          <br />
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <ReplayOutlinedIcon fontSize="small" color="warning" />
            <Typography>
              &nbsp;Frequent Retries: Is set if an array of the 20 most recent
              runs contains 4 or more successful retries.
            </Typography>
          </div>
          <br />
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <AcUnitOutlinedIcon fontSize="small" color="error" />
            <Typography>
              &nbsp;Unreliable: Is set if an array of the 20 most recent runs
              contains more than two occurrences of a pattern of a fail followed
              by a pass.
            </Typography>
          </div>
          <br />
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <CheckCircleOutlineOutlinedIcon fontSize="small" color="success" />
            <Typography>
              &nbsp;Reliable: Is set if none of the previous conditions are met.
            </Typography>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          href="/catalog/default/component/test-metric-api/docs/guides/test-metrics"
          onClick={() => {}}
          variant="contained"
        >
          Read Full Guide
        </Button>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
