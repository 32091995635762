import { makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import InfoIcon from '@material-ui/icons/Info';

type PanelHeaderProps = {
  title: string;
  note?: string;
};

const useStyles = makeStyles({
  container: {
    paddingLeft: '10px',
  },
  header: {
    fontSize: '24pt',
    marginBlockStart: '5px',
    marginBlockEnd: '5px',
    display: 'inline',
  },
  note: {
    marginLeft: 8,
    color: '#E6005C',
  },
});

export const PanelHeader = (props: PanelHeaderProps) => {
  const classes = useStyles();
  const { note, title } = props;
  return (
    <div className={classes.container}>
      <h2 className={classes.header}>{title}</h2>
      {note && (
        <Tooltip title={note} className={classes.note} placement="right">
          <InfoIcon fontSize="small" />
        </Tooltip>
      )}
    </div>
  );
};
