import React from 'react';
import { GuidePanel } from './GuidePanel';
import { GoldenPathPanel } from './GoldenPathPanel';
import { Content, Page } from '@backstage/core-components';
import { HomeSearchBar } from './HomeSearchBar';
import Toolkit from './Toolkit';
import { QuickPanel } from './QuickPanel';
import { NewAnnouncementBanner } from '@internal/plugin-qm-announcements';

export const HomePage = () => {
  /* We will shortly compose a pretty homepage here. */
  return (
    <Page themeId="tool">
      <Content>
        <NewAnnouncementBanner />
        <HomeSearchBar />
        <Toolkit />
        <QuickPanel />
        <GuidePanel />
        <GoldenPathPanel />
      </Content>
    </Page>
  );
};
