import { useState, useEffect } from 'react';
import {
  fetchApiRef,
  useApi,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

interface Options extends RequestInit {
  env: string;
}

type Identity = {
  email: string;
  id: string;
};
export type IdentityResponse = {
  data: Identity;
  loading: boolean;
  error: string | null;
};

const useIamIdentity = (options: Options): IdentityResponse => {
  const [identity, setIdentity] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { fetch } = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const { env } = options;

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const proxyUrl = await discoveryApi.getBaseUrl('qm-iam-proxy');

      const target = `${proxyUrl}/identity?env=${env.toLowerCase()}`;
      const response = await fetch(target, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      });
      if (!response.ok) {
        setError('Invalid Response');
      }
      const responseData = await response.json();
      if (responseData.code === 401) {
        setError('401 Unauthorized');
      }
      setIdentity(responseData);
    } catch (err: any) {
      setError(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env]);

  return { data: identity, loading, error };
};

export default useIamIdentity;
