import {
  CombinedResults,
  FailResult,
  Health,
  Namespaces,
  PassResult,
  Result,
} from './types';

export const DEFAULT_SERVICE = 'iam';
export const SUPPORT_TEXT = `Made with ❤️ by Christoph, Joseph, Rasha, Chris, Steve, Ryan, Paul, Hunter,
and Eric.`;

const OX_EXCELLENT = '#53BC81';
const OX_EXCELLENT_GRADIENT = '#3e8d61';
const OX_GREAT = '#12A1AA';
const OX_GREATIENT = '#0e797f'; // ha
const OX_GOOD = '#22A7F0';
const OX_GOOD_GRADIENT = '#1a7db4';
const OX_OKAY = '#FF9645';
const OX_OKAY_GRADIENT = '#bf7134';
const OX_POOR = '#FF585E';
const OX_POOR_GRADIENT = '#bf4247';

const gradientMap: Record<Health, string> = {
  excellent: `linear-gradient(to bottom right, ${OX_EXCELLENT}, ${OX_EXCELLENT_GRADIENT})`,
  great: `linear-gradient(to bottom right, ${OX_GREAT}, ${OX_GREATIENT})`,
  good: `linear-gradient(to bottom right, ${OX_GOOD}, ${OX_GOOD_GRADIENT})`,
  okay: `linear-gradient(to bottom right, ${OX_OKAY}, ${OX_OKAY_GRADIENT})`,
  poor: `linear-gradient(to bottom right, ${OX_POOR}, ${OX_POOR_GRADIENT})`,
  unknown: `linear-gradient(to bottom right, grey, black)`,
};

export const getGradient = (health: Health) => {
  return gradientMap[health];
};

const colorMap: Record<Health, string> = {
  excellent: OX_EXCELLENT,
  great: OX_GREAT,
  good: OX_GOOD,
  okay: OX_OKAY,
  poor: OX_POOR,
  unknown: 'grey',
};

const getColor = (health: Health) => {
  return colorMap[health];
};

const shapeResults = (results: Result[]) => {
  if (!results) {
    return {};
  }
  return results.reduce<CombinedResults>((acc, result) => {
    const { namespace, pass } = result;
    if (!acc[namespace]) {
      acc[namespace] = { passResults: [], failResults: [] };
    }
    if (pass) {
      acc[namespace].passResults.push(result as PassResult);
    } else {
      acc[namespace].failResults.push(result as FailResult);
    }
    return acc;
  }, {});
};

export { getColor, colorMap, shapeResults };

export function getScore(namespace: string, namespaces: Namespaces[]): Health {
  const item = namespaces.find(itm => itm.namespace === namespace);
  return item?.score ?? 'unknown';
}

export function getServiceName() {
  const firstSplit = window.location.pathname.split('component/')[1];
  if (firstSplit) {
    return firstSplit.split('/')[0];
  }
  return DEFAULT_SERVICE;
}
